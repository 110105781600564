var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseAccordionListItem', {
    attrs: {
      "label": _vm.label,
      "is-disabled": _vm.isDisabled,
      "default-is-open": _vm.defaultIsOpen
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "height": "100%",
            "align-items": "center",
            "gap": "8px",
            "color": "#FFF",
            "font-size": "14px",
            "margin-right": ['8px', '16px'],
            "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer'
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['14px', '18px'],
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(_vm.subLabel) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }