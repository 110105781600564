<template>
  <BaseAccordionListItem
    :label="label"
    :is-disabled="isDisabled"
    :default-is-open="defaultIsOpen"
  >
    <template #header>
      <c-flex
        height="100%"
        align-items="center"
        gap="8px"
        color="#FFF"
        font-size="14px"
        :margin-right="['8px', '16px']"
        :cursor="isDisabled ? 'not-allowed' : 'pointer'"
      >
        <c-text
          :font-size="['14px', '18px']"
          font-weight="500"
        >
          {{ subLabel }}
        </c-text>
      </c-flex>
    </template>
    <template #default>
      <slot />
    </template>
  </BaseAccordionListItem>
</template>

<script>
import BaseAccordionListItem from '@/components/elements/base-accordion-list-item.vue'

export default {
  name: 'AccordionCustomItem',
  components: {
    BaseAccordionListItem,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    defaultIsOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
