<template>
  <c-accordion
    :allow-toggle="true"
    :allow-multiple="false"
    d="flex"
    flex-direction="column"
    :gap="['16px', '20px']"
    :mb="['16px', '20px']"
  >
    <slot />
  </c-accordion>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'FormAccordionList',
  provide() {
    const accordion = {}

    Object.defineProperty(accordion, 'accordion_', {
      enumerable: true,
      get: () => this.accordion_,
    })
    
    return {
      accordion,
      setIsOpenAccordionItem: this.setIsOpenAccordionItem,
      registerAccordionItemId: this.registerAccordionItemId,
    }
  },
  props: {
    allowToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      accordion_: {},
    }
  },
  methods: {
    setIsOpenAccordionItem(id, value) {
      if (this.allowToggle) {
        for (const key in this.accordion_) {
          if (key === id) {
            continue
          }
          this.accordion_[key] = false
        }
      }
      this.accordion_[id] = value
    },
    registerAccordionItemId(id, value) {
      if (this.accordion_[id] === undefined) {
        Vue.set(this.accordion_, id, false)
        return
      }
      this.accordion_[id] = value
    },
  },
}
</script>