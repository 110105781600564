import { render, staticRenderFns } from "./base-accordion-list-item.vue?vue&type=template&id=90ca7bdc&scoped=true&"
import script from "./base-accordion-list-item.vue?vue&type=script&lang=js&"
export * from "./base-accordion-list-item.vue?vue&type=script&lang=js&"
import style0 from "./base-accordion-list-item.vue?vue&type=style&index=0&id=90ca7bdc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90ca7bdc",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CFlex: require('@chakra-ui/vue').CFlex, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem})
