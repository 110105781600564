var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-accordion-item', {
    attrs: {
      "border": "none",
      "is-open": _vm.defaultIsOpen ? undefined : _vm.isOpen,
      "default-is-open": _vm.defaultIsOpen
    },
    on: {
      "change": _vm.changeAccordion
    }
  }, [_c('c-accordion-header', {
    attrs: {
      "_hover": {
        bg: _vm.isDisabled ? 'neutral.lightGray' : _vm.isError ? 'danger.500' : 'primary.500'
      },
      "rounded": "8px",
      "background-color": _vm.isDisabled ? 'neutral.lightGray' : _vm.isError ? 'danger.400' : 'primary.400',
      "h": ['48px', '62px'],
      "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer'
    }
  }, [_c('c-box', {
    attrs: {
      "flex": "1",
      "text-align": "left",
      "color": "neutral.white",
      "font-family": "Roboto, sans-serif",
      "font-weight": "500",
      "font-size": ['14px', '18px']
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_vm._t("header"), _c('c-accordion-icon', {
    attrs: {
      "size": "32px",
      "name": "chevron-down",
      "color": "#FFF"
    }
  })], 2)], 1), _c('c-accordion-panel', {
    attrs: {
      "pb": "2"
    }
  }, [_vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }