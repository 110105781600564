<template>
  <c-flex
    background-color="neutral.superLightGray"
    border-radius="8px"
    flex-direction="column"
    padding="16px"
    justify-content="center"
    align-items="center"
    gap="8px"
  >
    <c-image
      :src="require('@/assets/images/image-no-data.svg')"
      height="auto"
      width="100px"
      alt="no data"
    />
    <c-text color="neutral.gray">
      {{ text }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    text: {
      type: String,
      default: 'Data belum tersedia',
    },
  },
}
</script>
