<template>
  <BaseAccordionList
    :allow-toggle="false"
  >
    <slot />
  </BaseAccordionList>
</template>

<script>
import BaseAccordionList from '@/components/elements/base-accordion-list.vue'

export default {
  name: 'AccordionCustom',
  components: {
    BaseAccordionList,
  },
}
</script>
