var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "background-color": "neutral.superLightGray",
      "border-radius": "8px",
      "flex-direction": "column",
      "padding": "16px",
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/images/image-no-data.svg'),
      "height": "auto",
      "width": "100px",
      "alt": "no data"
    }
  }), _c('c-text', {
    attrs: {
      "color": "neutral.gray"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }